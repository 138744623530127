import {PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import Apps from '@material-ui/icons/Apps';
import LogoutIcon from '@mui/icons-material/Logout';
import MuiCategoryIcon from '@material-ui/icons/Category';
import MuiMemoryIcon from '@material-ui/icons/Memory';
import MuiExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import {
  Settings as SidebarSettings,
  UserSettingsAuthProviders,
  UserSettingsMenu,
  UserSettingsProfileCard,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
  Button,
  DocsIcon
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import MuiMenuBookIcon from '@material-ui/icons/MenuBook';
import { identityApiRef, useApi, useApp } from '@backstage/core-plugin-api';
import BuildIcon from '@material-ui/icons/Build';
import React from 'react';


const useSidebarLogoStyles = makeStyles({
  root: {
    width: 100 * sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: 0,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();
  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home" >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) =>
(
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      {/* <SidebarItem icon={DocsIcon} to="announcements" text="Announcements"/> */}
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />} >
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        {/* <SidebarItem icon={MuiMenuBookIcon} to="catalog" text="Catalog" /> */}
        <SidebarDivider />
        {/* <SidebarItem icon={Apps} to="catalog?filters[kind]=system" text="System" /> */}
        <SidebarItem icon={MuiMemoryIcon} to="catalog?filters[kind]=component" text="Services/Component"  />
        <SidebarItem icon={MuiExtensionIcon} to="catalog?filters[kind]=api" text="API" />
        <SidebarDivider color='black' />
        {/* End global nav */}
        <SidebarItem icon={BuildIcon} to="entity-validation" text="Validator" />
        <SidebarItem icon={useApp().getSystemIcon('kind:resource')}to="newcomponent" text="New..." />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Register..." />
        {/* <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" /> */}
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      {/* <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings">
        <SidebarSettings />
        
      </SidebarGroup> */}
      <Logout />
    </Sidebar>

    {children}
  </SidebarPage>
);
const Logout = () => {
  const identityApi = useApi(identityApiRef);
  return (<SidebarItem icon={ExitToAppIcon} text="Logout" onClick={() => identityApi.signOut()}> </SidebarItem>)

}

/*
<SidebarItem icon={HomeIcon} to="catalog" text="Home">
<SidebarSubmenu title="Catalog" >
  <SidebarSubmenuItem
    title="Domains"
    to="catalog?filters[kind]=domain"
    icon={useApp().getSystemIcon('kind:domain')}
  />
  <SidebarSubmenuItem
    title="System"
    to="catalog?filters[kind]=system"
    icon={useApp().getSystemIcon('kind:system')}
  />
  <SidebarSubmenuItem
    title="Component"
    to="catalog?filters[kind]=component"
    icon={useApp().getSystemIcon('kind:component')}
  />
  <SidebarSubmenuItem
    title="APIs"
    to="catalog?filters[kind]=api"
    icon={useApp().getSystemIcon('kind:api')}
  />
  <SidebarDivider />
  <SidebarSubmenuItem
    title="Resources"
    to="catalog?filters[kind]=resource"
    icon={useApp().getSystemIcon('kind:resource')}
  />
  <SidebarDivider />
  <SidebarSubmenuItem
    title="Groups"
    to="catalog?filters[kind]=group"
    icon={useApp().getSystemIcon('kind:group')}
  />
  <SidebarSubmenuItem
    title="Users"
    to="catalog?filters[kind]=user"
    icon={useApp().getSystemIcon('kind:user')}
  />
</SidebarSubmenu>
</SidebarItem>
<SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
*/
